import React from 'react';
// // import sections
import Team from '../components/sections/Team';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Cta from '../components/sections/Cta';

class TeamSection extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {
    return (
      <React.Fragment>
        <Team/>
        <FeaturesSplit invertMobile topDivider imageFill />
        <Cta split />
      </React.Fragment>
    );
  }
}

export default TeamSection;