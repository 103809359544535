import React from 'react';
import SectionHeader from '../components/sections/partials/SectionHeader';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';
import { withTranslation } from "react-i18next";
import "../locales/i18n";
import { analytics } from '../firebase/firebase';

class Howorks extends React.Component {
  componentDidMount() {
    analytics.logEvent('howWorksPage_visited');
  }
  render() {
    const { t } = this.props;
    const noListStyle = {
      listStyle: 'none',
      border: 'none !important'
    }
    const boldWhite = {
      color: 'white',
      fontWeight: 'bolder'
    }
    const FaqTitle = {
      title: "FAQ's"
    }

    return (
      <React.Fragment>
        <GenericSection>
          <div className="container-xs">
            <h2 className="mt-0">{t('how_function.title')}</h2>
            <ol>
              <li>
                  <h4>{t('how_function.step1')}</h4>
                  <ul>
                    <li>{t('how_function.step1_point1')}</li>
                    <li>{t('how_function.step1_point2')}</li>
                  </ul>
              </li>
              <li>
                  <h4>{t('how_function.step2')}</h4>
                  <ul>
                    <li>{t('how_function.step2_point1')}</li>
                    <li>{t('how_function.step2_point2')}</li>
                    <li>{t('how_function.step2_point3')}</li>
                  </ul>
              </li>
              <li id="faq">
                  <h4>{t('how_function.step3')}</h4>
                  <ol>
                    <li>{t('how_function.step3_point1')}</li>
                    <ul>
                      <li style={noListStyle}>{t('how_function.step3_point1_sub1')}<span style={boldWhite}>{t('how_function.step3_point1_sub1_important')}</span></li>
                      <li style={noListStyle}>{t('how_function.step3_point1_sub2')}<span style={boldWhite}>{t('how_function.step3_point1_sub2_important')}</span></li>
                    </ul>
                    <li>{t('how_function.step3_point2')}</li>
                    <li>{t('how_function.step3_point3')}</li>
                    <ul>
                      <li style={noListStyle}>{t('how_function.step3_point3_sub1')}</li>
                      <li style={noListStyle}>{t('how_function.step3_point3_sub2')}</li>
                    </ul>
                    <li>{t('how_function.step3_point4')}</li>
                  </ol>
              </li>
              <li>
                  <h4>{t('how_function.step4')}</h4>
                  <ol>
                    <li>{t('how_function.step4_point1')}</li>
                    <ul>
                      <li style={noListStyle}>{t('how_function.step4_point2')}</li>
                      <li style={noListStyle}>{t('how_function.step4_point3')}</li>
                    </ul>
                  </ol>
              </li>
            </ol>
          </div>
        </GenericSection>
        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={FaqTitle} className="center-content" />
            <Accordion>
              <AccordionItem title={t('faq.first')}>
                {t('faq.first_answer')}
              </AccordionItem>
              <AccordionItem title={t('faq.second')}>
                {t('faq.second_answer')}
              </AccordionItem>
              <AccordionItem title={t('faq.third')}>
                {t('faq.third_answer')} <span role="img" aria-label="emoji-gui">😉</span>
              </AccordionItem>
              <AccordionItem title={t('faq.fourth')}>
                {t('faq.fourth_answer')}
              </AccordionItem>
              <AccordionItem title={t('faq.fifth')}>
                {t('faq.fifth_answer')}
              </AccordionItem>
              <AccordionItem title={t('faq.sexth')}>
                {t('faq.sexth_answer')}
              </AccordionItem>
              <AccordionItem title={t('faq.seventh')}>
                <section>
                <ul>
                  <li>
                    <h4>{t('faq.seventh_answer_1')}</h4>
                    <ul>
                      <li style={noListStyle}>{t('faq.seventh_answer_1_step_1')}
                      </li>
                      <li style={noListStyle}>{t('faq.seventh_answer_1_step_2')} 
                      </li>
                      <li style={noListStyle}>{t('faq.seventh_answer_1_step_3')} 
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h4>{t('faq.seventh_answer_2')}</h4>
                    <ul>
                      <li style={noListStyle}>{t('faq.seventh_answer_2_step_1')}
                      </li>
                      <li style={noListStyle}>{t('faq.seventh_answer_2_step_2')}
                      </li>
                      <li style={noListStyle}>{t('faq.seventh_answer_2_step_3')}
                      </li>
                    </ul>
                  </li>
                </ul>
                </section>
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>
        <Cta split />
      </React.Fragment>
    );
  }
}

export default withTranslation()(Howorks);