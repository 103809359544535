import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
// import Input from "../elements/Input";
// import Button from "../elements/Button";
// import Modal from "../elements/Modal";
import Image from "../elements/Image";
import swal from "sweetalert";
import { withTranslation } from "react-i18next";
import "../../locales/i18n";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

// const modalFormStyle = {
//   maxWidth: "320px",
//   margin: "0 auto",
// };

class Cta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoModalActive: false,
      closeModal: false,
      name: "",
      email: "",
    };
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.saveOnSheets = this.saveOnSheets.bind(this);
  }
  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  };
  handleName(event) {
    this.setState({ name: event.target.value });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  saveOnSheets = (event) => {
    event.preventDefault();
    fetch("https://api.apispreadsheets.com/data/9140/", {
      method: "POST",
      body: JSON.stringify({
        data: { name: this.state.name, email: this.state.email },
      }),
    }).then((res) => {
      if (res.status === 201) {
        swal(this.props.t("contact.saved"), "", "success", {
          button: this.props.t("contact.thx"),
        }).then(() => {
          this.closeModal(event);
        });
      } else {
        swal(this.props.t("contact.saved_not"), "", "error", {
          button: "Ok",
        }).then(() => {
          this.closeModal(event);
        });
      }
    });
  };
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      t,
      ...props
    } = this.props;

    const innerClasses = classNames(
      "cta-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider",
      split && "cta-split"
    );

    return (
      <section {...props}>
        <div className="center-content">
          <div className="container">
            <div className={innerClasses}>
              <div className="cta-slogan">
                <h3 className="m-0">¡{t("contact.slogan")}!</h3>
              </div>
              <div>
                <a
                  href="https://t.me/+Mem4JsWPCZpkZjVh"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={require("./../../assets/images/telegramLogo.svg")}
                    alt="Telegram"
                    width={76}
                    height={76}
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://twitter.com/bloinx_io?s=20"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={require("./../../assets/images/twitterLogo.svg")}
                    alt="Twitter"
                    width={76}
                    height={76}
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://www.instagram.com/bloinx_io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={require("./../../assets/images/instaLogo.svg")}
                    alt="Instagram"
                    width={76}
                    height={76}
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/Bloinx/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={require("./../../assets/images/faceBookLogo.svg")}
                    alt="FaceBook"
                    width={76}
                    height={76}
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://www.youtube.com/@bxsmartlabs3737"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={require("./../../assets/images/youtubeLogo.svg")}
                    alt="Youtube"
                    width={76}
                    height={76}
                  />
                </a>
              </div>
              {/* <div>
                <Button
                  color="SECONDARY"
                  aria-controls="demo-modal"
                  onClick={this.openModal}
                >
                  {t("contact.button")}
                </Button>
                <Modal
                  id="demo-modal"
                  show={this.state.demoModalActive}
                  handleClose={this.closeModal}
                >
                  <div className="center-content">
                    <h3 className="mt-16 mb-8">
                      ¡{t("contact.modal_title")} Bloinx!
                    </h3>
                    <p className="text-sm">{t("contact.modal_info")}</p>
                  </div>
                  <form style={modalFormStyle} onSubmit={this.saveOnSheets}>
                    <div className="mb-12">
                      <Input
                        type="text"
                        label="This is a label"
                        placeholder={t("contact.name")}
                        value={this.state.name}
                        onChange={this.handleName}
                        labelHidden
                      />
                      <Input
                        type="email"
                        label="This is a label"
                        placeholder={t("contact.mail")}
                        value={this.state.email}
                        onChange={this.handleEmail}
                        labelHidden
                      />
                    </div>
                    <Button color="primary" type="submit" value="Submit" wide>
                      {t("contact.sus")}
                    </Button>
                  </form>
                  <div className="center-content mt-24">
                    <a
                      className="text-xxs fw-500 tt-u"
                      aria-label="close"
                      href="#0"
                      onClick={this.closeModal}
                    >
                      {t("contact.later")}
                    </a>
                  </div>
                </Modal>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default withTranslation()(Cta);
