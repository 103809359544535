import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { withTranslation } from "react-i18next";
import "../../locales/i18n";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Team extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      t,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "team section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "team-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: this.props.t("team.title"),
      paragraph: this.props.t("team.par"),
    };

    const roundedImages = {
      borderRadius: "10%",
      objectFit: "cover",
    };

    return (
      <section {...props} className={outerClasses} id="team">
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-bottom"
            />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require("./../../assets/images/gabpp.jpg")}
                        alt="Gabriela"
                        style={roundedImages}
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">Gabriela</h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      M. Eng., B. Eng
                    </div>
                    <p className="m-0 text-sm">{t("team.gab")}</p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require("./../../assets/images/dompp.jpg")}
                        alt="Doménica"
                        style={roundedImages}
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">Doménica</h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      MBA, B. Marketing
                    </div>
                    <p className="m-0 text-sm">{t("team.dom")}</p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require("./../../assets/images/fiore.jpeg")}
                        alt="Andrea"
                        style={roundedImages}
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">Fiorella</h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Front End Developer
                    </div>
                    <p className="m-0 text-sm">{t("team.fiore")}</p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require("./../../assets/images/Artpp.jpg")}
                        alt="Arturo"
                        style={roundedImages}
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">Arturo</h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      B. E. Sc.
                    </div>
                    <p className="m-0 text-sm">{t("team.art")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default withTranslation()(Team);
