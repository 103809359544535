import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const Logo = ({ className, ...props }) => {
  const classes = classNames("brand", className);

  const logoImage = {
    objectFit: "contain",
  };

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={require("./../../../assets/images/bloinxLogo.png")}
            alt="Neon"
            style={logoImage}
            width={170}
          />
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
