import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { withTranslation } from "react-i18next";
import "../../locales/i18n";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      t,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-split section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-split-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    const sectionHeader = {
      title: this.props.t("team.trust"),
      paragraph: this.props.t("team.meet_hist"),
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>
              <div className="split-item" id="cld">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">{t("team.cdl_title")}</h3>
                  <p className="m-0">
                    <a href="https://creativedestructionlab.com/companies/bx-smart-labs/">
                      {t("team.cdl_link")}
                    </a>{" "}
                    {t("team.cdl_info")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-right",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    src={require("./../../assets/images/cdl.jpeg")}
                    alt="Creative_destruction_LAB"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item" id="celo">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">{t("team.celo_camp_title")}</h3>
                  <p className="m-0">
                    <a href="https://medium.com/celoorg/announcing-the-grand-prize-winners-of-celo-camp-batch-3-58dd0eef19fc">
                      {t("team.celo_camp_link")}
                    </a>{" "}
                    {t("team.celo_camp_info")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-right",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    src={require("./../../assets/images/bloinxCeloCert.png")}
                    alt="Celo_Camp_Cert"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item" id="encode">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">{t("team.encode_club_title")}</h3>
                  <p className="m-0">
                    <a href="https://medium.com/encode-club/announcing-the-encode-avalanche-club-participants-33b6d47196dd">
                      {t("team.encode_club_link")}
                    </a>{" "}
                    {t("team.encode_club_info")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-right",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    src={require("./../../assets/images/encodeClub.jpeg")}
                    alt="Encode_club_avalanche"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item" id="finastra">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">
                    {t("team.finastra_hack_title")}
                  </h3>
                  <p className="m-0">
                    <a href="https://www.finastra.com/news-events/press-releases/finastra-announces-winner-its-hackathon-tackle-bias-financial-services">
                      {t("team.finastra_hack_link")}
                    </a>{" "}
                    <br /> {t("team.finastra_hack_info")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-right",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    src={require("./../../assets/images/finastra.png")}
                    alt="finastra"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">
                    {t("team.talent_network_title")}
                  </h3>
                  <p className="m-0">
                    {t("team.talent_network_info_pt1")}{" "}
                    <a href="https://medium.com/latamtech/anunciamos-el-ranking-del-1-demo-day-organizado-por-blockchain-academy-chile-b93710be97b0">
                      Demo day Avalanche Developer Program
                    </a>{" "}
                    {t("team.talent_network_info_pt2")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-left",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    src={require("./../../assets/images/talent_network.jpg")}
                    alt="Winners-Talent_Land"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-right"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">{t("team.bha_title")}</h3>
                  <p className="m-0">
                    <a href="https://www.b4h.ngo/project/bloinx-by-bx-smart-labs">
                      {t("team.bha_link")}
                    </a>
                    . {t("team.bha_info")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-left",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    src={require("./../../assets/images/nominados.png")}
                    alt="Nominados_Blockchain_Awards"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item">
                <div
                  className="split-item-content center-content-mobile reveal-from-left"
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">{t("team.entrepeneur_title")}</h3>
                  <p className="m-0">
                    {t("team.entrepeneur_info_pt1")}{" "}
                    <a href="https://tec.mx/es/noticias/nacional/emprendedores/profesora-tec-lidera-app-de-ahorro-ganadora-en-copa-de">
                      {t("team.entrepeneur_info_link")}
                    </a>{" "}
                    {t("team.entrepeneur_info_pt2")}
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile reveal-from-right",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  <Image
                    src={require("./../../assets/images/world_cup_entrepeneur.png")}
                    alt="Winners Entrpeneurship World Cup"
                    width={528}
                    height={396}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default withTranslation()(FeaturesSplit);
