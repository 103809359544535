export const TRANSLATIONS_ES = {
  header: {
    works: "¿Cómo funciona?",
    team: "Equipo",
    app: "Ir a la App",
    docs: "Docs",
  },
  hero: {
    title: "Ayudando a crear comunidades de ahorro",
    subtitle:
      "Aplicación descentralizada que empodera al usuario a crear círculos de ahorro, generando confianza entre los participantes utilizando",
    button: "Quiero probar la versión Alpha",
  },
  home_video: {
    title: "Video Demostrativo",
  },
  home_info: {
    title: "Crea tu comunidad de ahorro",
    sub: "Conoce",
  },
  home_info_cards: {
    net: "Crece tu red",
    net_info: "Crea círculos de ahorro con personas",
    net_important: "alrededor del mundo",
    admin: "Fácil Administración",
    admin_info: "Autoadministra tus círculos de ahorro en un ambiente",
    admin_important: "transparente y verificable",
    reputation: "Reputación",
    reputation_info: "Al cumplir con tus pagos construye tu reputación",
    reputation_important: "dentro de la app",
    trust: "Confianza",
    trust_info:
      "Ten la certeza de que las reglas se seguirán, tu confianza está depositada en un sistema basado en",
    trust_important: "blockchain",
    verify: "Verifica",
    verify_info:
      "Tu información y transacción es verificada en tiempo real a través de",
    verify_important: "exploradores de blockchain",
    tech: "Tecnología",
    tech_info: "Desarrollado sobre blockchain",
  },
  roadmap: {
    cliff:
      "Quédate en contacto con nosotros y acompáñanos de cerca en ésta nueva experiencia",
    y21: "Prueba piloto",
    q2_22: "Versión Beta en la red de Celo",
    q3_22: "BLX Tokenomics v1",
    q1_23: "Beta Publica red Polygon",
    q2_23: "BLX Tokenomics v2",
    q3_23: "Recompensas en el Token BLX",
    q4_23: "Rondas Públicas con sistema de reputación",
  },
  contact: {
    slogan: "Contáctanos aquí",
    button: "CONTÁCTANOS AQUÍ",
    modal_title: "Conoce lo último de",
    modal_info:
      "Déjanos mantenerte al tanto cada vez que tengamos increíbles noticias para ti",
    name: "Nombre Completo",
    mail: "Correo Electrónico",
    sus: "Subscribir",
    later: "Paso, tal vez después",
    saved: "¡Listo, nos mantendremos en contacto contigo!",
    thx: "Gracias",
    saved_not: "¡Lo sentimos, algo aslió mal, intenta de nuevo!",
  },
  team: {
    title: "Te mandamos un saludo ✌🏼",
    par:
      "Nosotros (además de ti) somos Bloinx y nos preocupamos cada día por hacer que las personas como tú tengan la herramienta definitiva que les facilite el ahorro de una manera efectiva y colaborativa, todo esto con la seguridad y confianza que brinda la tecnología Blockchain",
    gab:
      "Oráculo de proyectos. En sus ratos libres comparte su conocimiento con jóvenes, además de ser una gran cantante.",
    dom:
      "Gurú del marketing y los negocios, amante de crear cosas nuevas e irreverentes. En sus ratos libres predice el futuro y lee los astros.",
    art:
      "Ser mistico del desarrollo web. En sus ratos libres entrena para estar preparado ante el ataque de cualquier virus (web o real).",
    fiore:
      "Clarividente crypto. Ama los gatos y lee el tarot cuando no está soñando con NFT's",
    ordu: "",
    trust: "Conoce nuestra historia",
    meet_hist: "",
    talent_network_title:
      "1er Lugar en talentnetwork reto de Finanzas Descentralizadas con AVA",
    talent_network_info_pt1: "Ganadores del",
    talent_network_info_pt2:
      "junto con la participación de Blockchain Academy Chile para el desarrollo del modelo de negocio y apoyo técnico.",
    entrepeneur_title: "Ganadores Entrepeneur World Cup México",
    entrepeneur_info_pt1: "1er Lugar en etapa de idea de la",
    entrepeneur_info_link: "Copa mundial de Emprendimiento 2020",
    entrepeneur_info_pt2: "y seleccionados como Top 100 mundial.",
    bha_title: "Nominados al Blockchain for Humanity Awards",
    bha_link: "Nominados en la categoría de Community Development",
    bha_info:
      "En etapa de Proof of Concept con la segunda versión del prototipo terminada e impacto en personas que no tienen acceso a instituciones financieras.",
    celo_camp_title: "Celo Camp 3ra edición",
    celo_camp_link: "Apoyados por la Fundación Celo",
    celo_camp_info:
      'durante este programa de 8 semanas desarrollamos un prototipo de aplicación mobile. Al terminar obtuvimos el reconocimiento "The Innovating On Money Award".',
    finastra_hack_title: "Ganadores de Finastra’s Hack to the Future 3",
    finastra_hack_link:
      "Finastra lanzó este hackaton para ayudar a construir un futuro imparcial.",
    finastra_hack_info:
      "El evento contó con más de 600 participantes de más de 50 países.",
    encode_club_title: "Encode Avalanche Club",
    encode_club_link:
      "El propósito de Encode es educar, apoyar y catalizar la aparición de nuevos talentos en le espacio blockchain,",
    encode_club_info:
      "en este programa de 10 semanas mejoraremos la experiencia de usuario para que nuestra dapp sea accesible para todos.",
    cdl_title: "Creative Destruction LAB",
    cdl_link: "Proyecto seleccionado en el programa CDL Toronto,",
    cdl_info:
      " el cual consta de mentorías para iniciativas basadas en tecnología. Esta experiencia nos ha reunido con expertos como Allen Lau y Mike Abramski, entre otros, para impulsar el desarrollo, alcanzar el potencial y aumentar las probabilidades de éxito de nuestra compañía.",
  },
  how_function: {
    title: "¿Cómo funciona?",
    step1: "Crea tu ronda",
    step1_point1:
      "Al ser el creador de la ronda y administrador, deberás indicar el número de invitados y y el monto del pago quincenal.",
    step1_point2:
      "Toma en cuenta que no podrás modificar estos datos y que cada invitado puede tener solo una ronda activa.",
    step2: "Registra a tus invitados",
    step2_point1:
      "Al crear una nueva ronda se generará un número el cual deberás compartir con tus invitados.",
    step2_point2: "Tus invitados deberán registrarse y pagar la cuota inicial.",
    step2_point3:
      "Antes de iniciar la ronda deberás verificar que las direcciones registradas correspondan a las de tus invitados. En caso de que un invitado no esté en la ronda correcta, podrás eliminarlo. Recuerda que una vez iniciada la ronda, no podrás hacer cambios.",
    step3: "Pagos y retiros",
    step3_point1: "Cuando la ronda inicie, todos los invitados tendrán:",
    step3_point1_sub1: "3 días para ",
    step3_point1_sub1_important: "pago quincenal",
    step3_point1_sub2: "12 días para ",
    step3_point1_sub2_important: "retirar",
    step3_point2:
      "El turno avanzará cuando cada invitado retire. Si notas que tus invitados no lo hacen a tiempo, podrás avanzar el turno.",
    step3_point3:
      "Si uno de tus invitados no paga a tiempo, se tomará su cuota inicial para cubrir su aportación en ese turno.",
    step3_point3_sub1:
      "3.1 La primera vez que un invitado no pague a tiempo, no podrá disponer de su cuota inicial al finalizar su ronda. Y no afectará a los demás invitados.",
    step3_point3_sub2:
      "3.2 A partir de la segunda vez que un invitado no pague a tiempo, impactará en el monto del que todos los invitados podrán disponer al final de la ronda.",
    step3_point4:
      "Si tu o tus invitados tienen pagos pendientes, podrán ponerse al corriente mientras esté corriendo la ronda.",
    step4: "Fin de la ronda",
    step4_point1: "Cuando el último invitado retire, podrás:",
    step4_point2: "Reiniciar la ronda",
    step4_point3: "Finalizar la ronda",
  },
  faq: {
    first: "¿Qué significa aplicación descentralizada?",
    first_answer:
      "A diferencia de una aplicación que intercambia información con un servidor, una aplicación descentralizada interactúa con una blockchain. Nuestra aplicación interactúa con contratos inteligentes en blockchain que manejan la administración del círculo de ahorro y aseguran transparencia en todas las transacciones.",
    second: "¿Qué es la prueba de la versión Alpha?",
    second_answer:
      "Es una prueba en la que queremos obtener información sobre usabilidad y funcionalidad de potenciales usuarios. Esta prueba se hará en una testnet con fondos ficticios.",
    third: "¿Obtengo algún beneficio al participar en la prueba Alpha?",
    third_answer:
      "Nos ayudarás a mejorar la aplicación para que una vez que salga a mercado pueda ser fácil de usar por otros usuarios. Quienes participen en esta etapa recibirán sorpresas en el lanzamiento.",
    fourth: "¿Puedo participar si no conozco sobre blockchain?",
    fourth_answer:
      "¡Claro! Queremos que nuestra aplicación sea fácil de usar sin importar si han usado otras aplicaciones descentralizadas así que tus comentarios serán muy valiosos.",
    fifth: "¿Cuándo y de qué monto se realizan los pagos?",
    fifth_answer:
      "Tú y tus invitados establecen la fecha y monto que quieren ahorrar.",
    sexth: "¿Cuántas personas pueden participar?",
    sexth_answer: "Cada círculo de ahorro se conforma de máximo 6 invitados.",
    seventh: "¿Qué pasará con el monto acumulado de las cuotas iniciales?",
    seventh_answer_1: "En caso de reiniciar la ronda:",
    seventh_answer_1_step_1:
      "Si tu invitado está al corriente en pagos, se     tomará el monto de su cuota inicial para la nueva ronda.",
    seventh_answer_1_step_2:
      "Si tu invitado no está al corriente en pagos, deberá pagar la cuota adelantada antes de inicar la siguiente ronda.",
    seventh_answer_1_step_3:
      "Si uno o más invitados tienen más de un pago atrasado, el monto existente se dividirá entre los usuarios sin atrasos en pagos.",
    seventh_answer_2: "En caso de finalizar la ronda:",
    seventh_answer_2_step_1:
      "Si tu invitado está al corriente en pagos, se le devolverá su cuota adelantada.",
    seventh_answer_2_step_2:
      "Si tu invitado tiene un pago que no cubrió, no se le devolverá la cuota adelantada.",
    seventh_answer_2_step_3:
      "Si uno o más invitados tienen más de un pago atrasado, el monto existente se dividirá entre los usuarios sin atrasos en pagos.",
  },
};
