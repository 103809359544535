import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroSplit from '../components/sections/HeroSplit';
import GenericSection from '../components/sections/GenericSection';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Modal from '../components/elements/Modal';
import { withTranslation } from "react-i18next";
import { analytics } from '../firebase/firebase';
import "../locales/i18n";

class Home extends React.Component {

  state = {
    videoModalActive: false
  }

  componentDidMount() {
    analytics.logEvent('homePage_visited');
  };
  
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  render() {
    const fitContain = {
      objectFit: 'cover'
    }
    const VideoTitle = {
      title: this.props.t("home_video.title")
    }

    return (
      <React.Fragment>
        <HeroSplit className="illustration-section-01" />
        <GenericSection topDivider className="center-content">
          <SectionHeader data={VideoTitle} className="reveal-from-bottom" />
          <div className="reveal-from-bottom">
            <a
              data-video="https://www.youtube.com/embed/G2-f8ppnRZg"
              href="#0"
              aria-controls="video-modal"
              onClick={this.openModal}
            >
              <Image
                src={require('./../assets/images/bloinx_cover.png')}
                alt="Video"
                style={fitContain}
                width={712}
                height={400} />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={this.state.videoModalActive}
            handleClose={this.closeModal}
            video="https://www.youtube.com/embed/G2-f8ppnRZg"
            videoTag="iframe" />
        </GenericSection>
        <FeaturesTiles topDivider className="center-content" />
        <Roadmap topDivider />
        <Cta split />
      </React.Fragment>
    );
  }
}

export default withTranslation()(Home);