import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Timeline from "../elements/Timeline";
import TimelineItem from "../elements/TimelineItem";
import { withTranslation } from "react-i18next";
import "../../locales/i18n";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class Roadmap extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      t,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "roadmap section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "roadmap-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: "Bloinx Roadmap",
      paragraph: this.props.t("roadmap.cliff"),
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="" />
              <TimelineItem title="2021">{t("roadmap.y21")}</TimelineItem>
              <TimelineItem title="Q2 2022">{t("roadmap.q2_22")}</TimelineItem>
              <TimelineItem title="Q3 2022">{t("roadmap.q3_22")}</TimelineItem>
              <TimelineItem title="Q1 2023">{t("roadmap.q1_23")}</TimelineItem>
              <TimelineItem title="Q2 2023">{t("roadmap.q2_23")}</TimelineItem>
              <TimelineItem title="Q3 2023">{t("roadmap.q3_23")}</TimelineItem>
              <TimelineItem title="Q4 2023">{t("roadmap.q4_23")}</TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default withTranslation()(Roadmap);
