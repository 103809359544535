export const TRANSLATIONS_EN = {
  header: {
    works: "How it works?",
    team: "Team",
    app: "Launch App",
    docs: "Docs",
  },
  hero: {
    title: "Supporting people build save up communities ",
    subtitle:
      "Descentralized app that boosts users to create save up reliable communities by using",
    button: "I want to try Alpha Version",
  },
  home_video: {
    title: "Demonstrative Video",
  },
  home_info: {
    title: "This is Bloinx",
    sub: "",
  },
  home_info_cards: {
    net: "Expand your network",
    net_info: "Create savings community with people",
    net_important: "all over the world",
    admin: "Easy",
    admin_info: "Manage your savings circles in a completely",
    admin_important: "transparent, verifiable and trustworthy enviroment",
    reputation: "Reputation",
    reputation_info:
      "Build your reputation fulfilling your payments inside the app",
    reputation_important: "inside the app",
    trust: "Trust",
    trust_info:
      "Rest assured that policies will be applied, your trust is placed in a system based on",
    trust_important: "blockchain",
    verify: "Verify",
    verify_info:
      "Your information and transactions are verified in real time through",
    verify_important: "blockchain explorers",
    tech: "Technology",
    tech_info: "Developed on Blockchain",
  },
  roadmap: {
    cliff: "Stay in touch with us and join us closely in this new experience",
    y21: "Closed Beta",
    q2_22: "Beta version in Celo network",
    q3_22: "BLX Tokenomics v1",
    q1_23: "Public Beta test Polygon network",
    q2_23: "BLX Tokenomics v2",
    q3_23: "BLX Token rewards",
    q4_23: "Public Rounds with reputation system",
  },
  contact: {
    slogan: "Contact us here",
    button: "CLICK HERE",
    modal_title: "Get the latest from",
    modal_info:
      "Let us keep you posted every time we have amazing news for you",
    name: "Name",
    mail: "Email",
    sus: "Subscribe",
    later: "Maybe later",
    saved: "Allright, we will keep in touch!",
    thx: "Thanks!",
    saved_not: "Sorry, something went wrong, trey again!",
  },
  team: {
    title: "We say Hey! ✌🏼",
    par:
      "Us, Bloinx work everyday to bring you the ultimate tool to help you save in an effective and collaborative way. All of this in a secure and trusty environment supported by Blockchain technology",
    gab:
      "Project oracle. In her spare time, she shares her knowledge with young people, she also has the talent of being a great singer.",
    dom:
      "Guru of marketing and business, lover of creating new and irreverent things. In her spare time she predicts the future and reads the stars.",
    art:
      "Mystical being of web development. In his spare time he climbs mountains with his rescued dogs to be prepared for the attack of any virus (web or real).",
    fiore:
      "Crypto clairvoyant. She loves cats, she reads the tarot when she is not dreaming about NFTs.",
    ordu: "",
    trust: "Get to know our great story",
    meet_hist: "",
    talent_network_title:
      "1st Place in talentnetwork Decentralized Finance challenge with AVA",
    talent_network_info_pt1: "Winners of",
    talent_network_info_pt2:
      "hand on hand with the participation of Blockchain Academy Chile for the business model development and technical support.",
    entrepeneur_title: "Winners of Entrepeneur World Cup México",
    entrepeneur_info_pt1: "1st Place on idea stage of",
    entrepeneur_info_link: "Entrepeneurship world cup 2020",
    entrepeneur_info_pt2: "and selected as the World Top 100.",
    bha_title: "Nominated for Blockchain for Humanity Awards",
    bha_link: "Nominated in the Community Development category",
    bha_info:
      "In Proof of Concept stage with the second version of the prototype finished and impact on people who do not have access to financial institutions.",
    celo_camp_title: "Celo Camp Batch 3",
    celo_camp_link: "Supported by Celo Foundation",
    celo_camp_info:
      'during this 8 week program we developed a mobile app prototype. At graduation we were awarded the "The Innovating On Money Award".',
    finastra_hack_title: "Winners of Finastra’s Hack to the Future 3",
    finastra_hack_link:
      "Finastra launched this hackathon to help build an unbiased fintech future.",
    finastra_hack_info:
      "The event saw over 600 participants from more than 50 countries.",
    encode_club_title: "Encode Avalanche Club",
    encode_club_link:
      "The purpose of Encode is to educate, support and catalyze the emergence of new talent into the blockchain space,",
    encode_club_info:
      "in this 10 week program we will improve the user experience to make our dapp accessible to all.",
    cdl_title: "Creative Destruction LAB",
    cdl_link: "Selected project to participate in the CDL Toronto program,",
    cdl_info:
      " which consists in mentoring for technology-based initiatives. This experience has brought us together with experts such as Allen Lau and Mike Abramski, among others, to drive development, reach potential and increase the chances of success for our company",
  },
  how_function: {
    title: "How does it work?",
    step1: "Create a round",
    step1_point1:
      "As the creator and administrator of the round, you must indicate the number of guests and the amount of the biweekly payment.",
    step1_point2:
      "Take into account that you will not be able to modify this data and that each guest can have only one active round.",
    step2: "Register your guests",
    step2_point1:
      "When creating a new round, a number will be generated, you must share with your guests.",
    step2_point2: "Your guests will need to register and pay the initial fee.",
    step2_point3:
      "Before starting the round, you must verify that the registered addresses correspond to those of your guests. In case a guest is not in the correct round, you can remove them. Remember that once the round has started, you will not be able to make changes.",
    step3: "Payments and withdrawals",
    step3_point1: "When the round starts, all guests will have:",
    step3_point1_sub1: "3 days to ",
    step3_point1_sub1_important: "biweekly payment",
    step3_point1_sub2: "12 days to ",
    step3_point1_sub2_important: "withdraw",
    step3_point2:
      "The turn will advance when each guest withdraws. If you notice that your guests do not do it on time, you can advance the turn.",
    step3_point3:
      "If one of your guests does not pay on time, their initial fee will be taken to cover their contribution for that shift.",
    step3_point3_sub1:
      "3.1 The first time a guest does not pay on time, they will not be able to have their initial fee at the end of their round. This will not affect other guests.",
    step3_point3_sub2:
      "3.2 The second time that a guest does not pay on time, it will impact the amount that each guests will have at the end of the round.",
    step3_point4:
      "If you or your guests have pending payments, they can catch up while the round is running.",
    step4: "End of round",
    step4_point1: "When the last guest checks out, you can:",
    step4_point2: "Restart round",
    step4_point3: "End round",
  },
  faq: {
    first: "What does decentralized application mean?",
    first_answer:
      "Unlike an application that exchanges information with a server, a decentralized application interacts with a blockchain. Our application interacts with smart contracts on the blockchain that manage the management of the savings circle and ensure transparency in all transactions.",
    second: "What is the Alpha version trial?",
    second_answer:
      "It is a test in which we want to obtain information on usability and functionality of potential users. This test will be done on a testnet with fictitious funds.",
    third: "Do I get any benefit from participating in the Alpha test?",
    third_answer:
      "You will help us to improve the application so that once it is released it can be easy to use by other users. Those who participate in this stage will receive surprises at the launch.",
    fourth: "Can I participate if I don't know about blockchain?",
    fourth_answer:
      "Sure! We want our application to be easy to use regardless of whether you have used other decentralized applications so your feedback will be very valuable.",
    fifth: "When and how much are the payments made?",
    fifth_answer:
      "You and your guests set the date and amount you want to save.",
    sexth: "How many people can participate?",
    sexth_answer: "Each savings circle is made up of a maximum of 6 guests.",
    seventh:
      "What will happen to the accumulated amount of the initial installments?",
    seventh_answer_1: "In case of restarting the round:",
    seventh_answer_1_step_1:
      "If your guest is current in payments, the amount of his initial fee will be taken for the new round.",
    seventh_answer_1_step_2:
      "If your guest is not up to date with payments, he must pay the advance fee before starting the next round.",
    seventh_answer_1_step_3:
      "If one or more guests have more than one late payment, the existing amount will be divided among the users without payment arrears.",
    seventh_answer_2: "In case of finishing the round:",
    seventh_answer_2_step_1:
      "If your guest is current on payments, their advance fee will be returned.",
    seventh_answer_2_step_2:
      "If your guest has a payment that they did not cover, the advance fee will not be returned.",
    seventh_answer_2_step_3:
      "If one or more guests have more than one late payment, the existing amount will be divided among the users without payment arrears.",
  },
};
